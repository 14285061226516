document.addEventListener('DOMContentLoaded', function() {
    // console.log('yoast FAQs');

    var faq = document.querySelector('.wp-block-yoast-faq-block');

    if (faq != null) {



        var questions = faq.querySelectorAll('.schema-faq-question');
        var answers = faq.querySelectorAll('.schema-faq-answer');

        questions.forEach(function(question, index) {
            var answerId = 'faq-answer-' + (index + 1);
            var questionText = question.textContent;

            var answer = question.nextElementSibling;
            answer.id = answerId;
            answer.setAttribute('aria-hidden', 'true');
            answer.setAttribute('aria-labelledby', 'faq-question-' + (index + 1));

            var newQuestion = document.createElement('a');
            newQuestion.className = 'schema-faq-question';
            newQuestion.href = '#';
            newQuestion.setAttribute('aria-expanded', 'false');
            newQuestion.tabIndex = 0;
            newQuestion.id = 'faq-question-' + (index + 1);
            newQuestion.setAttribute('aria-controls', answerId);
            newQuestion.textContent = questionText;

            question.replaceWith(newQuestion);
        });

        questions = faq.querySelectorAll('.schema-faq-question'); // reassign questions variable to include new anchor tags

        questions.forEach(function(question) {
            question.addEventListener('click', toggleFaq);
            question.addEventListener('keydown', function(event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
                    toggleFaq.call(this, event);
                }
            });
        });

        function toggleFaq(event) {
            event.preventDefault();
            var thisQuestion = this;
            var thisAnswer = thisQuestion.nextElementSibling;

            questions.forEach(function(question) {
                if (question !== thisQuestion) {
                    question.classList.remove('faq-q-open');
                    question.setAttribute('aria-expanded', 'false');
                }
            });

            answers.forEach(function(answer) {
                if (answer !== thisAnswer) {
                    answer.classList.remove('faq-a-open');
                    answer.style.display = 'none';
                    answer.setAttribute('aria-hidden', 'true');
                }
            });

            if (thisAnswer.style.display === 'block') {
                thisQuestion.classList.remove('faq-q-open');
                thisQuestion.setAttribute('aria-expanded', 'false');
                thisAnswer.classList.remove('faq-a-open');
                thisAnswer.style.display = 'none';
                thisAnswer.setAttribute('aria-hidden', 'true');
            } else {
                thisQuestion.classList.add('faq-q-open');
                thisQuestion.setAttribute('aria-expanded', 'true');
                thisAnswer.classList.add('faq-a-open');
                thisAnswer.style.display = 'block';
                thisAnswer.setAttribute('aria-hidden', 'false');
            }
        }

    }
});


// jQuery(document).ready(function() {

//     console.log('yoast FAQs')

//     var $faq = jQuery('.wp-block-yoast-faq-block');
//     var $questions = $faq.find('.schema-faq-question');
//     var $answers = $faq.find('.schema-faq-answer');

//     $questions.each(function(index) {
//         var answerId = 'faq-answer-' + (index + 1);
//         var question = jQuery(this).text();
//         jQuery(this).next('.schema-faq-answer').attr('id', answerId).attr('aria-hidden', 'true').attr('aria-labelledby', 'faq-question-' + (index + 1));
//         jQuery(this).replaceWith('<a class="schema-faq-question" href="#" aria-expanded="false" tabindex="0" id="faq-question-' + (index + 1) + '" aria-controls="' + answerId + '">' + question + '</a>');
//     });

//     $questions = $faq.find('.schema-faq-question'); // reassign $questions variable to include new anchor tags

//     $questions.on('click keydown', function(event) {
//         if (event.type === 'click' || event.keyCode === 13 || event.keyCode === 32) {
//             var $this = jQuery(this);
//             var $thisAnswer = $this.siblings('.schema-faq-answer');
            
//             $questions.not(this).removeClass('faq-q-open').attr('aria-expanded', 'false');
//             $answers.not($thisAnswer).removeClass('faq-a-open').slideUp().attr('aria-hidden', 'true');
            
//             if ($thisAnswer.is(':visible')) {
//                 $this.removeClass('faq-q-open').attr('aria-expanded', 'false');
//                 $thisAnswer.removeClass('faq-a-open').slideUp().attr('aria-hidden', 'true');
//             } else {
//                 $this.addClass('faq-q-open').attr('aria-expanded', 'true');
//                 $thisAnswer.addClass('faq-a-open').slideDown().attr('aria-hidden', 'false');
//             }
//             event.preventDefault();
//         }
//     });
// });
