// core version + navigation, pagination modules:
import Swiper from 'swiper';
// import { Navigation, Pagination } from 'swiper/modules';
import { Navigation, Pagination, EffectFade, Autoplay, Controller, Thumbs } from 'swiper/modules';

// init Swiper:
const swiper = new Swiper('.js-swiper', {
  // Install modules
  modules: [Navigation, Pagination, EffectFade, Autoplay],
  spaceBetween: 30,
  // effect: "fade",
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  fadeEffect: { crossFade: true },
  virtualTranslate: true,
  autoplay: {
      delay: 2500,
      disableOnInteraction: true,
  },
  speed: 800, 
  slidersPerView: 1,
  effect: "fade"
});


// COLLECTION SWIPERS

[...document.getElementsByClassName('js-collection-swiper')].forEach(el => {

  // console.log('el', el);

  var collectionSettings = {
    modules: [Navigation, Pagination, EffectFade, Autoplay, Controller],
    // slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    speed: 800, 
    effect: "fade",
    allowTouchMove: false,
    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },
  }

  var mainImages    = new Swiper( el.getElementsByClassName('js-main-image')[0], collectionSettings );
  var swatchImages  = new Swiper( el.getElementsByClassName('js-swatch-image')[0], collectionSettings );
  
  // here's where you link the two sliders above
  // mainImages.controller.control = swatchImages;
  // swatchImages.controller.control = mainImages;
  
});




// STONE SWIPERS

[...document.getElementsByClassName('js-stone-swiper')].forEach(el => {

  // console.log('el', el);

  // var collectionSettings = {
  //   modules: [Navigation, Pagination, EffectFade, Autoplay, Controller],
  //   slidesPerView: 1,
  //   loop: true,
  //   spaceBetween: 30,
  //   speed: 800, 
  //   effect: "slide"
  // }

  // var stoneImages    = new Swiper( el, collectionSettings );

  var thumbsSwiper = new Swiper(el.getElementsByClassName('js-thumbsSwiper')[0], {
    modules: [Navigation, Pagination, EffectFade, Autoplay, Controller, Thumbs],
    loop: true,
    spaceBetween: 10,
    slidesPerView: 4,
    centered: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    freeMode: true,
    watchSlidesProgress: true
  });

  var swiper2 = new Swiper(el.getElementsByClassName('js-stoneSwiper')[0], {
    modules: [Navigation, Pagination, EffectFade, Autoplay, Controller, Thumbs],
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: thumbsSwiper,
    },
  });


  // var swatchImages  = new Swiper( el.getElementsByClassName('js-swatch-image')[0], collectionSettings );
  
  // here's where you link the two sliders above
  // mainImages.controller.control = swatchImages;
  // swatchImages.controller.control = mainImages;
  
});



  