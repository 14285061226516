// // Photoswipe
// import PhotoSwipeLightbox from "photoswipe";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
// import PhotoSwipeLightbox from '../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';
// import PhotoSwipe from '../../node_modules/photoswipe/dist/photoswipe.esm.js';

// import PhotoSwipeLightbox from 'photoswipe/lightbox';
// import PhotoSwipe from '../../node_modules/photoswipe/dist/photoswipe.esm.js';

const lightbox = new PhotoSwipeLightbox({
  gallery: '.js-photoswipe',
  children: 'a',
  pswpModule: PhotoSwipe
});

lightbox.init();