// G LIGHTBOX // https://github.com/biati-digital/glightbox
// import GLightbox from 'glightbox';

// const lightbox = GLightbox({
//   // ...options
// });

// import Player from '@vimeo/player';

document.addEventListener('DOMContentLoaded', function () {
    // const lightbox = GLightbox();
    const lightbox = GLightbox({
      // ...options
    });
    console.log('lightbox', lightbox);
    // Additional configuration or event handling can go here
    // lightbox.on('close', () => {
    //     var vidThumbs = document.querySelectorAll("iframe.js-video-thumb--video");
        
    //     vidThumbs.forEach(vidThumbs => {
    //         const player = new Vimeo.Player(vidThumbs);
    //         player.play()
    //         // console.log('js-video-thumb--video');
    //     });
    // });
});