//////////////////////////////////////////
/// UTURN - Nav Visibility on scroll /////
//////////////////////////////////////////

// var TNY;

var _visible = true;

function uturn () {

    var _scrollY,
        _countingUp             = 0,
        _countingDown           = 0,
        _lastScrollY            = 0,
        _container              = document.querySelector('body'), // Change this to the element you want to watch
        _ticking                = false,
        scrollChangeToUp        = new CustomEvent("scrollChangeToUp", {}),
        scrollChangeToDown      = new CustomEvent("scrollChangeToDown", {});

    events();

    /**
     * Callback for our scroll event - just
     * keeps a track on the last scroll value
     */
    function onScroll() {
        _lastScrollY = window.scrollY;
        requestTick();
    }

    /**
     * Calls rAF if it's not already
     * been done already
     */
    function requestTick() {
        if(!_ticking) {
            requestAnimFrame(update);
            _ticking = true;
        }
    }

    /**
     * Our animation callback
     */
    function update() {
        updateLocation();
        // allow further rAFs to be called
        _ticking = false;
    }

    /**
     * Scroll related event setup
     */
    function events(remove){
      var op = remove ? 'remove' : 'add';

      // only listen for scroll events
      window.addEventListener('scroll', onScroll, false);

      // shim layer with setTimeout fallback
      window.requestAnimFrame = (function(){
        return  window.requestAnimationFrame       ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame    ||
                window.oRequestAnimationFrame      ||
                window.msRequestAnimationFrame     ||
                function( callback ){
                  window.setTimeout(callback, 1000 / 60);
                };
      })();
    }

    /**
     * Do a check if scrolling up
     * Expand check by confirmed the scrolling up is actually happening
     */
    function checkIsScrollingUp(y){
      var bounds = _container.getBoundingClientRect(),
          isGoingUp;
      if(bounds.top < 0 && (bounds.top+bounds.height-window.innerHeight) > 0){
        if(_scrollY > y){
            document.dispatchEvent(scrollChangeToUp);
        } else {
            document.dispatchEvent(scrollChangeToDown);
        }
      }
    }

    /**
     * This is the "controller" for this entire process
     */
    var updateLocation = function(){
      // console.log('fn running');
      var y = window.scrollY;
      checkIsScrollingUp(y);
      _scrollY = y;
    };
};

// TNY.uturn = uturn;

// uturn(document);

uturn();

function hideWhileScrolling() {
  document.getElementsByTagName( 'html' )[0].classList.add("nav-is-hidden");
  document.getElementsByTagName( 'html' )[0].classList.remove("nav-is-visible");
};

function displayWhileScrolling() {
  document.getElementsByTagName( 'html' )[0].classList.remove("nav-is-hidden");
  document.getElementsByTagName( 'html' )[0].classList.add("nav-is-visible");
};

function checkScrollPosition() {
  if(window.scrollY < 100){
    document.getElementsByTagName( 'html' )[0].classList.add("page-is-at-top");
  } else {
    document.getElementsByTagName( 'html' )[0].classList.remove("page-is-at-top");
  }
};

checkScrollPosition();

document.addEventListener('scrollChangeToDown', function(){
  // console.log('_visible');
  // console.log(_visible);
  checkScrollPosition()
  if(_visible == true){
    hideWhileScrolling();
    _visible = false;
  }
});

document.addEventListener('scrollChangeToUp', function(){
  // console.log(_visible);
  checkScrollPosition()
  if(_visible == false){
    displayWhileScrolling();
    _visible = true;
  }
});


